<template>
  <div>
    <div v-if="this.$route.name === 'Funds'">
      <Funds />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import Funds from "@/views/companyApp/funds/Funds.vue";

export default {
  name: 'FundsParent',
  components: {
    Funds
  }
}
</script>